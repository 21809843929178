import React from 'react'
import './App.css';
// import { withAuthenticator } from '@aws-amplify/ui-react';
import Home from './pages';
import Publications from './pages/publications';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// import { Auth } from 'aws-amplify'



function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path='/' component={Home} exact />
          <Route path='/Publications' component={Publications} exact />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
