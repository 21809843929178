import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
// import { Auth } from 'aws-amplify';
import { animateScroll as scroll } from 'react-scroll';
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
  NavBtn,
  // NavBtnLink
} from './NavbarElements';

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  // const [btnDialog, setBtnDialog] = useState('Sign In');

  // useEffect(() => {
  //   Auth.currentAuthenticatedUser()
  //     .then(session => {
  //       setBtnDialog('Sign Out');
  //     })
  //     .catch(err => {
  //       setBtnDialog('Sign In')
  //       console.log(err)
  //     })
  // });


  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo onClick={toggleHome} to='/'>
              Weston Faber, Ph.D.
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  to='research'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >
                  Research
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='ai'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >
                  Mission
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='pubs'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >
                  Publications
                </NavLinks>
              </NavItem>
            </NavMenu>
            {/* <NavBtn>
              <NavBtnLink to='/laytrader'>Lay Trader</NavBtnLink>
            </NavBtn> */}
            <NavBtn>
              {/* <NavBtnLink to='/signin'>{btnDialog}</NavBtnLink> */}
              {/* <NavBtnLink to='/'>Sign In</NavBtnLink> */}
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
