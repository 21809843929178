import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import InfoSectionRouter from '../components/InfoSectionRouter';
import {
  homeObjOne,
} from '../components/InfoSection/Data';
import {
  performanceInfoSection,
  // homeObjThree
} from '../components/InfoSectionRouter/RouterData';
import Research from '../components/Research';
// import CoinTable from '../components/CoinBuildForms/CoinPage';
// import Research from '../components/Research';

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <Research />
      <InfoSection {...homeObjOne} />
      {/* <InfoSectionRouter {...analysisInfoSection} /> */}
      <InfoSectionRouter {...performanceInfoSection} />
      <Footer />
    </>
  );
}

export default Home;
