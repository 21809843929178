
import {
    PublicationContainer,
    PublicationWrapper,
} from './PublicationElements';
function Publications() {
    const text = "U. R. Mishra, S. Chakravorty, W.R. Faber, I. I. Hussein, “Geometric Solution to Probabilistic Admissible Region (G-PAR)”, Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2022. \n\n " +
        "Waqar Zaidi, Thomas Kelecy, W.R. Faber, Moriba Jah, “Establishing a Chain of Digital Forensics for Space Object Behavior Using Distributed Ledger Technology,” Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2021. \n\n" +
        "Shawn W. Abernethy, Jr., Emily Gerber, William DeLude, W.R.  Faber; Thomas Kelecy Taylor Nave, “Adapting New Processes to Support Improved Space Based Surveillance Ground Operations,” Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2021. \n\n" +
        "U. R. Mishra, W.R. Faber, S. Chakravorty, I. I. Hussein “A Subset Simulation Based Technique for Calculating the Probability of Collision,” Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2021.\n\n" +
        "W. R. Faber, R. Furfaro, R. Linares, “Deep Learning for SSA”, Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2021. SHORT COURSE\n\n" +
        "W. R. Faber , “Sensor Management Prisoner's Dilemma: A Deep Reinforcement Learning Approach” Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2020.\n\n" +
        "W. R. Faber, R. Furfaro, R. Linares, “Deep Learning for SSA”, Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2020.\n\n" +
        "Joe Coughlin, Rohit Mital, and W. R. Faber, “Demystifying Machine Learning: Short Course”, Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2020.\n\n" +
        "J. T. Kent, S. Bhattacharjee, W. R. Faber and I. I. Hussein, \"A Unified Approach to The Orbital Tracking Problem,\" 2020 IEEE International Conference on Multisensor Fusion and Integration for Intelligent Systems (MFI), Karlsruhe, Germany, 2020, pp. 82-87, doi: 10.1109/MFI49285.2020.9235258.\n\n" +
        "John T. Kent, Shambo Bhattacharjee, Weston R. Faber, Islam I. Hussein, “Revisiting the orbital tracking problem,” 2019 arXiv arXiv:1909.03793  (or) arXiv:1909.03793v2\n\n" +
        "W. R. Faber, S. Chakravorty, and I. I. Hussein, “Tracking Collisional Cascading using R-FISST” Journal of Astronautical Sciences special edition 2019. In Review.\n\n" +
        "Joe Coughlin, Rohit Mital, and W. R. Faber, “Demystifying Machine Learning: Short Course”, Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2019.\n\n" +
        "W. R. Faber, E. Lambert, and I.I. Hussein, “Autonomous Space-Based Shape Estimation Using Range Sensors”, Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2019.\n\n" +
        "John T. Kent, Shambo Bhattacharjee, and Weston R. Faber, IAC 2019 “Understanding the Distribution of the Propagated Angles-Only Position Vector”, To be published In Proc. of 70th International Astronautical Congress, Washington D.C. 21-25 Oct. 2019\n\n" +
        "John T. Kent, Shambo Bhattacharjee, and Weston R. Faber, IAC 2019 “Revisiting the Filtering Problem”, To be published In Proc. of 70th International Astronautical Congress, Washington D.C. 21-25 Oct. 2019\n\n" +
        "W. R. Faber, E. Lambert, and I.I. Hussein, “Autonomous Space-Based Shape Estimation Using Range Sensors”, To be published In Proc. of 70th International Astronautical Congress, Washington D.C. 21-25 Oct. 2019\n\n" +
        "D. A.V. Raihan, W. R. Faber, S. Chakravorty, and I. I. Hussein, “Sensor Scheduling Under Action Dependent Decision-Making Epochs”, to be presented at the 22nd International Conference on Information Fusion, Ottawa, Canada, July 2nd -5th, 2019\n\n" +
        "D. A.V. Raihan, W. R. Faber, S. Chakravorty, and I. I. Hussein, “'Particle Gaussian Mixture Filters: Application and Performance Evaluation” to be presented at the 22nd International Conference on Information Fusion, Ottawa, Canada, July 2nd -5th, 2019\n\n" +
        "S. Chakravorty and W. R. Faber, “On the Relationship between RFS and MHT Based Multi-target Tracking”, to be presented at the 22nd International Conference on Information Fusion, Ottawa, Canada, July 2nd -5th, 2019\n\n" +
        "W. R. Faber, W. Zaidi, and J. Paul W. Schumacher, “Early Blast Point Determination for Large GEO fragmentation Events”, Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2019.\n\n" +
        "Shambo Bhattacharjee, J.T. Kent, W. R. Faber, and I. I. Hussein, “Understanding the Effect of Perturbations on the Gaussianity of Various Coordinates for the Space Object Tracking Problem” Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2018.\n\n" +
        "W. R. Faber, W. Zaidi, and J. Paul W. Schumacher, “Blast Point Determination For Space Object Fragmentation Events”, Proceedings of the AAS/AIAA Astrodynamics Specialist Conference, Snowbird, Utah, August, 2018,\n\n" +
        "W. H. Zaidi, M. Mercurio, W. R. Faber, and P. W. Schumacher Jr., “Sequential Orbit Estimation and Prediction Using Modified Equinoctial Elements”, Proceedings of the AAS/AIAA Astrodynamics Specialist Conference, Snowbird, Utah, August, 2018,\n\n" +
        "D. A.V. Raihan, W. R. Faber, S. Chakravorty, and I. I. Hussein, “Parallel Markov Chain Monte Carlo For Sensor Scheduling”, Proceedings of the AAS/AIAA Astrodynamics Specialist Conference, Snowbird, Utah, August, 2018, \n\n" +
        "John T. Kent, Shambo Bhattacharjee, Islam I. Hussein, Weston R. Faber, and Moriba K. Jah, “Filtering when object custody is ambiguous”, 21st International Conference on Information Fusion, Cambridge, UK July, 2018\n\n" +
        "W.R. Faber, S. Chakravorty, and I. I. Hussein, “Tracking Collisional Cascading using R-FISST” John Junkins Dynamical Systems Symposium, College Station, Tx, 2018\n\n" +
        "John T. Kent, Shambo Bhattacharjee, Islam I. Hussein, Weston R. Faber, andMoriba K. Jah, “Fisher-Bingham-Kent Mixture Models for Angles-Only Observation Processing” 28th AIAA/AAS Space Flight Mechanics Meeting, Kissimmee, FL, January 8-12, 2018\n\n" +
        "M. Mercurio, W. H. Zaidi, W. R. Faber, I. I. Hussein, C. W. T. Roscoe, and M. P. Wilkins, “ Development of The Probabilistic Admissible Region With Equinoctial Orbital Elements”, 28th AIAA/AAS Space Flight Mechanics Meeting, Kissimmee, FL, January 8-12, 2018\n\n" +
        "W.R. Faber, I. I. Hussein, J. T. Kent, S.Bhattacharjee, and M.K.Jah, “Optical Data Processing Using Directional Statistics In a Multiple Hypothesis Framework With Maneuvering Objects” 28th AIAA/AAS Space Flight Mechanics Meeting, Kissimmee, FL, January 8-12, 2018\n\n" +
        "Shambo Bhattacharjee, J.T. Kent, W. R. Faber, I. I. Hussein, M. K. Jah, “Application of Directional Statistics to Problems in SSA”, 1st IAA Conference on Space Situational Awareness (ICSSA), At Orlando, FL, USA, Nov 2018\n\n" +
        "W. H. Zaidi, W. R. Faber, I. I. Hussein, M. Mercurio, C. W. T. Roscoe, M. P. Wilkins, and P. W. Schumacher Jr., “Debris Object Orbit Initialization Using the Probabilistic Admissible Region with Asynchronous Heterogeneous Measurements.” Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS) September 2017.\n\n" +
        "W. R. Faber, W. Zaidi, I. Hussein, C. W. T. Roscoe, M. P. Wilkins, and J. Paul W. Schumacher, “Application Of Multi-Hypothesis Sequential Monte Carlo For Breakup Analysis,” 2017. Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS) \n\n" +
        "W.R. Faber, I. I. Hussein, J. T. Kent, S.Bhattacharjee, and M.K.Jah “ FBK Optical Data Association in a Multi-Hypothesis Framework with Maneuvers” Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS)  September 2017. \n\n" +
        "W. H. Zaidi, W. R. Faber, I. I. Hussein, M. Mercurio, C. W. T. Roscoe, M. P. Wilkins, and P. W. Schumacher Jr., “Debris Object Orbit Initialization Using the Probabilistic Admissible Region with Asynchronous Heterogeneous Measurements.” IAC 2017\n\n" +
        "W. H. Zaidi, W. R. Faber, I. I. Hussein, M. Mercurio, C. W. T. Roscoe, M. P. Wilkins, and P. W. Schumacher Jr., “Debris Object Orbit Initialization Using the Probabilistic Admissible Region with Asynchronous Heterogeneous Measurements.” Proceedings of the AAS/AIAA Astrodynamics Specialist Conference, Stevenson, WA, August 21-24, 2017, Pre-Print AAS 17-592. \n\n" +
        "W.R. Faber, I. I. Hussein, J. T. Kent, S.Bhattacharjee, and M.K.Jah “Optical Data Association in a Multi-Hypothesis Framework with Maneuvers” AAS/AIAA Astrodynamics Specialist Conference Columbia River Gorge, Stevenson, WA. August 2017. 17-639\n\n" +
        "W. R. Faber, W. Zaidi, I. Hussein, C. W. T. Roscoe, M. P. Wilkins, and J. Paul W. Schumacher, “Application Of Multi-Hypothesis Sequential Monte Carlo For Breakup Analysis,” 2017. Proceedings of the AAS/AIAA Astrodynamics Specialist Conference, Columbia River Gorge, Stevenson, WA Paper AAS 17-579.\n\n" +
        "Michael Mercurio, W.R. Faber I. I. Hussein, M. P. Wilkins, C. W. T. Roscoe, and P. W. Schumacher, Jr., “Debris-Laser Beam Probability Of Intersection In The Presence Of Uncertainty,” The proceedings of ESA 7th European Conference On Space Debris April 18th-21st 2017.\n\n" +
        "W. R. Faber, S. Chakravorty, Ph.D, and Islam I. Hussein, Ph.D. “Multiple Space Object Tracking with R-FISST,” Proc. of AAS/AIAA Space Flight Mechanics Meeting, San Antonio,TX, February 5-9 201, Paper AAS 17-477.\n\n" +
        "I. I. Hussein, W. Zaidi, W. R. Faber, C. W.T Roscoe, M. P. Wilkins, P. W. Schumacher, Jr., and M. Bolden. “Application of Sequential Monte Carlo Methods For Space Object Tracking,” Proc. of AAS/AIAA Space Flight Mechanics Meeting, San Antonio,TX, February 5-9 201, Paper AAS 17-415. \n\n" +
        "I. I. Hussein, W. Zaidi, C. W.T Roscoe, M. P. Wilkins, W. R. Faber, S. Chakravorty, P. W. Schumacher, Jr.  “A High-Performance Computing Study of a Scalable FISST-Based Approach to Multi-Target, Multi-Sensor Tracking,” Proc. of AMOS Maui, Hawaii September 2016.\n\n" +
        "W. Faber, S. Chakravorty, and I. Hussein,  “R-FISST and the Data Association Problem With Applications to Space Situational Awareness,” AIAA Space and Astronautics Forum and Exposition, September 2016.\n\n" +
        "W. Faber, S. Chakravorty, and I. Hussein, “Multi-Object Tracking with Multiple Birth, Death, and Spawn Scenarios Using A Randomized Hypothesis Generation Technique (RFISST),” International Conference of Information Fusion, July 2016, \n\n" +
        "W. Faber, S. Chakravorty, and I. Hussein, “A randomized sampling-based approach to multi-object tracking,” International Conference of Information Fusion, July 2015. \n\n" +
        "W. Faber, S. Chakravorty, and I. Hussein, “A randomized sampling-based approach to multi-object tracking with comparison to HOMHT,” IAAS/AIAA Astrodynamics Specialists Conference, August 2015. \n\n" +
        "W. Faber, S. Chakravorty, and I. Hussein, “Multi-Target Tracking Using A Randomized Hypothesis Generation Technique,” IEEE Transactions on Aerospace and Electronic Systems, Accepted in publication process \n\n"


    return (
        <PublicationContainer>
            <PublicationWrapper>
                {text}
            </PublicationWrapper>
        </PublicationContainer>
    )
}

export default Publications;