import React from 'react';
import space from '../../images/space.svg'
import cos from '../../images/cos.svg'
import gt from '../../images/gt.svg'
import w3 from '../../images/w3.svg'
import graph from '../../images/graph.svg'
import target from '../../images/vision.svg'
import auto from '../../images/auto.svg'
import cmd from '../../images/cmd.svg'
import ai from '../../images/ai.svg'

import {
  ResearchContainer,
  ResearchH1,
  ResearchWrapper,
  ResearchCard,
  ResearchIcon,
  ResearchH2,
  ResearchP
} from './ResearchElements';

const Research = () => {
  return (
    <ResearchContainer id='research'>
      <ResearchH1>Research Interests</ResearchH1>
      <ResearchWrapper>
        <ResearchCard to='ai'>
          <ResearchIcon src={ai} />
          <ResearchH2>Artificial General Intelligence</ResearchH2>
          <ResearchP>
            Journey to develop true cognitive systems.
          </ResearchP>
        </ResearchCard>
        <ResearchCard>
          <ResearchIcon src={space} />
          <ResearchH2>Space</ResearchH2>
          <ResearchP>
            Ensuring safe access to space for current and future generations.
          </ResearchP>
        </ResearchCard>
        <ResearchCard>
          <ResearchIcon src={gt} />
          <ResearchH2>Game Theory</ResearchH2>
          <ResearchP>
            Understanding behavior and its impact to complex decision making.
          </ResearchP>
        </ResearchCard>
        <ResearchCard>
          <ResearchIcon src={w3} />
          <ResearchH2>Web3 and SW Development</ResearchH2>
          <ResearchP>
            Modular Open Systems Approach (MOSA), Microservices and the transition to decentralization.
          </ResearchP>
        </ResearchCard>
        <ResearchCard>
          <ResearchIcon src={graph} />
          <ResearchH2>Graph Theory</ResearchH2>
          <ResearchP>
            Utilization of Graphs and Graph Neural Networks for multi-domain applications.
          </ResearchP>
        </ResearchCard>
        <ResearchCard>
          <ResearchIcon src={target} />
          <ResearchH2>Multi-target Tracking</ResearchH2>
          <ResearchP>
            Expanding theory and techniques for multi-target tracking and overall situational awareness.
          </ResearchP>
        </ResearchCard>
        <ResearchCard>
          <ResearchIcon src={cos} />
          <ResearchH2>Cosmology and Physics</ResearchH2>
          <ResearchP>
            Understanding the universe and how it works.
          </ResearchP>
        </ResearchCard>
        <ResearchCard>
          <ResearchIcon src={auto} />
          <ResearchH2>Autonomy</ResearchH2>
          <ResearchP>
            Transitioning AI from simulation to real systems.
          </ResearchP>
        </ResearchCard>
        <ResearchCard>
          <ResearchIcon src={cmd} />
          <ResearchH2>Complex Decision Making</ResearchH2>
          <ResearchP>
            Expanding theory and techniques to solve Complex Decision Processes.
          </ResearchP>
        </ResearchCard>
      </ResearchWrapper>
    </ResearchContainer>
  );
};

export default Research;
