import React from 'react';
import Footer from '../components/Footer';
import NavbarHome from '../components/NavbarHome';
import PublicationSection from '../components/PublicationsSection'
import ScrollToTop from '../components/ScrollToTop';


function Publications() {

    return (
        <>
            <ScrollToTop />
            <NavbarHome />
            <PublicationSection />
            <Footer />
        </>
    );
}

export default Publications;
