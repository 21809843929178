import React from 'react';
import video from '../../videos/video2.mp4'
import img from '../../images/HeadShot.JPG'
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  ImgWrap,
  Img,
  Column1,
  Column2
} from './HeroElements';

function HeroSection() {
  // const [hover, setHover] = useState(false);
  // const [video, setVideo] = useState({ fileUrl: '' });

  // useEffect(() => {
  //   GetHeroVideo().then(vid => setVideo({ fileUrl: vid }));
  // }, []);
  const text = "Research Scientist and Full Stack (ish) Developer. \n Creating impactful technologies. \nSpace Domain Awareness and Joint All Domain Command and Control Subject Matter Expert. \n Contributing author and lecturer."

  // const onHover = () => {
  //   setHover(!hover);
  // };
  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg playsInline autoPlay loop muted src={video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        <Column1>
          <ImgWrap>
            <Img src={img} />
          </ImgWrap>
        </Column1>
        <Column2>
          <HeroH1>Weston R. Faber, Ph.D.</HeroH1>
          <HeroP>{text}</HeroP>
          {/* <HeroBtnWrapper>
          <Button
            to='/cashcrew'
            smooth='true'
            duration={500}
            spy='true'
            exact='true'
            offset={-80}
            primary='true'
            dark='true'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Tools {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper> */}
        </Column2>
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;
