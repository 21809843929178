import ai from '../../images/augmentedIntel.jpg';
// import hand from '../../images/hand.jpg'
import masses from '../../images/masses.jpg'

export const homeObjOne = {
  id: 'ai',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Research Mission Statement',
  headline: 'Not just the ability to pass the Turing Test',
  description: 'Instead the development of software that has a positive impact on society. I want to develop systems that are capable of cognitive function such that they can, from inception with very simplisitic capabilities, develop very complex abilities without a human defined objective function. This by neccessity includes the development of their own objective functions, reasoning capabilities, and senses to aid them in understanding reward from loss. I am particulary interested in shaping these technologies to improve our daily lives and space exploration.',
  buttonLabel: 'Get Started',
  imgStart: false,
  img: ai,
  alt: 'Ape',
  dark: true,
  primary: true,
  darkText: false
};

// export const homeObjTwo = {
//   id: 'discover',
//   lightBg: true,
//   lightText: false,
//   lightTextDesc: false,
//   topLine: 'Unlimited Access',
//   headline: 'Login to your account at any time',
//   description:
//     'We have you covered no matter where you are located. All you need is an internet connection and a phone or computer.',
//   buttonLabel: 'Learn More',
//   imgStart: true,
//   img: require('../../images/svg-2.svg'),
//   alt: 'Piggybank',
//   dark: false,
//   primary: false,
//   darkText: true
// };

export const homeObjThree = {
  id: 'GetStarted',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Data Providers',
  headline: 'Data to the masses',
  description:
    "Cutting out the go between to enable Space Situational Awareness data providers to reach a demand driven market and have a positive impact in space development. Retain control over your data. Our tools let you monetize your data without restricting your network, data rights, or customer base.",
  buttonLabel: 'Community',
  imgStart: false,
  img: masses,
  alt: 'Other',
  dark: true,
  primary: true,
  darkText: false
};
