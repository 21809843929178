import environment from '../../images/environment.jpg';
import analyze from '../../images/analyze.jpg';

export const analysisInfoSection = {
  id: 'space',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Space Environmentalism',
  headline: 'Tools promoting the responsible utilization of space',
  description: 'Working together to enable access to space be it through direct or digital exploration of the environment. Colaborating with government, industry and academia to promote the rapid development of approved tools for planning, monitoring, and evaluating space activities.',
  buttonLabel: 'Discussion Page',
  imgStart: true,
  img: environment,
  alt: 'discussion',
  dark: false,
  primary: false,
  darkText: true,
  linkString: '/discussion'
};

export const performanceInfoSection = {
  id: 'pubs',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'For the most up to date list click below',
  headline: 'Recent Publications',
  description: 'U. R. Mishra, S. Chakravorty, W.R. Faber, I. I. Hussein, “Geometric Solution to Probabilistic Admissible Region (G-PAR)”, Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2022. \n\n  Waqar Zaidi, Thomas Kelecy, W.R. Faber, Moriba Jah, “Establishing a Chain of Digital Forensics for Space Object Behavior Using Distributed Ledger Technology,” Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2021. \n\n ' +
    'Shawn W. Abernethy, Jr., Emily Gerber, William DeLude, W.R.  Faber; Thomas Kelecy Taylor Nave, “Adapting New Processes to Support Improved Space Based Surveillance Ground Operations,” Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2021. \n\n' +
    'U. R. Mishra, W.R. Faber, S. Chakravorty, I. I. Hussein “A Subset Simulation Based Technique for Calculating the Probability of Collision,” Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2021. \n\n' +
    'W. R. Faber, R. Furfaro, R. Linares, “Deep Learning for SSA”, Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2021. SHORT COURSE \n\n' +
    'W. R. Faber , “Sensor Management Prisoner’s Dilemma: A Deep Reinforcement Learning Approach” Proceedings of The Advanced Maui Optical and Space Surveillance Technologies (AMOS), Maui, Hi, September 2020.',
  buttonLabel: 'Complete List',
  imgStart: true,
  img: analyze,
  alt: 'performance',
  dark: false,
  primary: false,
  darkText: true,
  linkString: '/Publications'
};


// export const homeObjTwo = {
//   id: 'discover',
//   lightBg: true,
//   lightText: false,
//   lightTextDesc: false,
//   topLine: 'Unlimited Access',
//   headline: 'Login to your account at any time',
//   description:
//     'We have you covered no matter where you are located. All you need is an internet connection and a phone or computer.',
//   buttonLabel: 'Learn More',
//   imgStart: true,
//   img: require('../../images/svg-2.svg'),
//   alt: 'Piggybank',
//   dark: false,
//   primary: false,
//   darkText: true
// };